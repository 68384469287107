import { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { baseURL } from "../../constants/properties";
import { FaSync, FaTimesCircle } from "react-icons/fa";
import { styles } from "../../constants/styles";
import { translate } from "../../helpers/translations";
import { SearchContext } from "../Contexts/Context";
import Pagination from "../../helpers/pagination";


export default function SearchPage() {
    const [searchParams] = useSearchParams();
    const query = searchParams.get('query');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { language } = useContext(SearchContext)
    const [pageCounts, setPageCounts] = useState(0);
    const page = searchParams.get('page');
    const [currentPage, setCurrentPage] = useState(page ?? 1);
    const navigate = useNavigate();
    
   
    useEffect(() => {
        if (query) handleSearchItems();

            navigate(`/search?${new URLSearchParams({ query: query, page:currentPage })}`);
        
    }, [query, currentPage]);

    const handleSearchItems = async () => {
        setLoading(true);
        try {
            const res = await fetch(
                `${baseURL}/news/?${new URLSearchParams({ search: query, page: currentPage })}`
            );
        ;

            if (!res.ok) throw new Error('Search API error.');

            const data = await res.json();
         
            setPageCounts(Math.ceil(data.count / 6));
            setItems(data.results);
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
        
    };

    if (!query) window.location = '/';

    return (
        <div className="landing-items-container margin-280px" style={styles.bottomMargin}>
            {loading ? (
                <div style={styles.centerText}>
                    <FaSync style={styles.searchCenteredIcon} />
                    <div style={styles.loadingRecords}>
                        {translate(language, 'loading_records')}
                    </div>
                </div>
            ) : ''}
            {!loading && items?.length < 1 ? (
                <div style={styles.centerText}>
                    <FaTimesCircle style={styles.searchCenteredIcon} />
                    <div style={styles.loadingRecords}>
                        {translate(language, 'no_records_found')}
                    </div>
                </div>
            ) : ''}
            {items && items.length > 0 ? (
                <div style={styles.searchResultTitle}>
                    <h1>
                        {translate(language, 'search_result')}
                        {` "${query}"`}
                        {` (${items.length})`}
                    </h1>
                </div>
            ) : ''}
            <div className="landing-container">
                {items && items.length > 0 ? items.map(item => (
                    <div key={item.slug} className="landing-items-container-infos">
                        <a href={`news/${item.slug}`}>
                            <div className="new-image-wrapper">
                                <img
                                  src={`${item.img}`}
                                  alt={item.title}
                                  loading="lazy"
                                />
                            </div>
                            <div>
                                <span className="time">
                                    {item.date}
                                </span>
                                <span className="header">
                                    {item.title}
                                </span>
                                <span className="vrclad">
                                    {translate(language, 'see_more')}
                                </span>
                            </div>
                        </a>
                    </div>
                )) : ''}
            </div>
            <div className="news-container">
            <Pagination pageCounts={pageCounts} setCurrentPage={setCurrentPage}/>
            </div>
        </div>
    );
}