export const styles = {
    subscribeInput: {
        marginTop: 5,
    },
    subscribeMessage: {
        color: '#fff',
        fontSize: 12,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    searchBox: {
        padding: 20,
    },
    searchItem: {},
    searchLink: {
        fontSize: 12,
        textDecoration: 'underline',
    },
    searchItemArrow: {
        color: '#000',
        margin: '0 2px 0 0',
        fontSize: 10,
    },
    loadingRecords: {
        fontSize: 13,
        textAlign: 'center',
    },
    centerText: {
        textAlign: 'center',
        marginBottom: 50,
    },
    searchCenteredIcon: {
        color: '#BCBCBC',
        fontSize: 40,
        marginBottom: 5,
    },
    searchResultTitle: {
        marginBottom: 15,
        fontSize: 11,
    },
    bottomMargin: {
        marginBottom: 50,
    },
};