import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default function Gallery({ img }) {
	const onInit = () => {
		console.log('lightGallery has been initialized');
	};
	return (
		<div
			className='App'
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			{img ? (
				<LightGallery onInit={onInit} speed={1} plugins={[lgZoom, lgThumbnail]}>
					{img.map((image, index) => (
						<a key={index} className='gallery-item' href={image.photo}>
							<img
								alt={`img${index}`}
								src={`${image.photo}`}
								style={{
									width: '270px',
									height: '150px',
									objectFit: 'cover',
									margin: '10px',
								}}
							/>
						</a>
					))}
				</LightGallery>
			) : (
				''
			)}
		</div>
	);
}
