import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { translate } from "../../helpers/translations";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SearchInput({ language }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const query = searchParams.get('query');
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (query) setSearch(query);
    }, [query]);

    const handleSearch = (key) => {
        if(search.length < 3) return;

        if (key === 'Enter' || key === 'icon') {
            navigate(`/search?${new URLSearchParams({ query: search })}`);
        }
    }

    return (
        <div className='hover'>
            <input 
              type="search" 
              placeholder={translate(language, 'search')}
              style={{
                borderBottom: search ? "1px solid white" : "", 
                color: search ? "white" : "",
              }}
              defaultValue={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => handleSearch(e.key)}
            />
            <FaSearch onClick={(e) => handleSearch('icon')} style={{ color: search ? "#00A859" : "" }} />
        </div>
    );
}