import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

export default function Pagination ({  setCurrentPage, pageCounts})  {
    return (
    <div className='pagination'>
    <div className='left-space'></div>
    <ReactPaginate
        previousLabel={<FaArrowLeft />}
        nextLabel={<FaArrowRight />}
        pageCount={pageCounts}
        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        containerClassName={'paginationButtons'}
        previousLinkClassName={'previusButton'}
        nextLinkClassName={'nextButton'}  
        disabledClassName={'disabledButton'}
        activeClassName={'activeButton'}
        onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}
    />
</div>
    )
    
}