import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { FaAngleRight } from 'react-icons/fa'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import logo from '../images/logo.png'
import { SearchContext } from './Contexts/Context'
import { post } from 'jquery'
import Subscribe from './subscription/Subscribe'

function Footer() {
  const { language } = useContext(SearchContext)
  const goToTop = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [menu, setMenu] = useState({})

  useEffect(() => {
    const link = 'https://khulo.gov.ge/api/site_menu1.php';
    fetch(link)
      .then((response) => response.json())
      .then((data) => {


        setMenu(data.menu)

        //  console.log('menu', menu)


        //  console.log('object entries', Object.entries(menu))
        // Object.entries(menu).map(item =>{

        //   if(item[1].level == 1)
        //   console.log('entries item', item[1].name_eng , item[1].level)
        // })

      });



  }, [])

  const [subscribe, setSubscribe] = useState('');
  const [message, setMessage] = useState(null);

  const handleClick = async (e) => {
      if(!(await isValidEmail(subscribe)).valid) {
        setMessage({ type: 'error', message: (await isValidEmail(subscribe)).message });
        return;
      }

      const formData = new URLSearchParams();
      formData.append('email', subscribe);

      const res = await fetch('https://khulo.gov.ge/api/subscribe.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
      });
      
      if (!res.ok) {
        setMessage({ type: 'error', message: 'Something went wrond.' });
      }

      setMessage({ type: 'success', message: 'You have been successfully subscribed.' });
  }

  const isValidEmail = async (email) => {
      if (email.length <= 5) {
        return { valid: false, message: 'Email should contain more than 5 symbols.' };
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        return { valid: false, message: 'Invalid email address format.' };
      }

      const alreadySubscribed = await isAlreadySubscribed(email);
      if(alreadySubscribed) {
        return { valid: false, message: 'You have already subscribed to this newsletter.' };
      }

      return { valid: true, message: 'Valid email address format.' };
  };

  const isAlreadySubscribed = async (email) => {
      const formData = new URLSearchParams();
      formData.append('email', email);

      const res = await fetch('https://khulo.gov.ge/api/check-email.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
      });

      const status = await res.json();
      return Boolean(status);
  };

  return (
    <div className='footer'>

      <div className="topFooter padding-280px">

        <div className="">
          <Link to='/meria-samartlebrivi-aqtebi'>
            <img src={require('../images/meriaSamarlebriviaqtebi.png')} alt="" />
          </Link>
        </div>

        <div className="">
          <Link to='/sajaro-Informacia'>
            <img src={require('../images/Group 272.png')} alt="" />
          </Link>
        </div>
        <div className="">
          <Link to='/peticia'>
          <img src={require('../images/Group 271.png')} alt="" />
          </Link>
        
        </div>

        <div className="">
          <Link to='/writeMessage'>
            <img src={require('../images/Group 270.png')} alt="" />
          </Link>
        </div>
      </div>
      <div className="bottomFooter ">
        {<Subscribe language={language} />}
        <div className="footer-nav padding-280px">
          {Object.entries(menu).length && Object.entries(menu).map((item) => {
            if (item[1].parent_id == 1 && item[1].name_geo != 'სამართლებრივი აქტები' && item[1].name_geo != 'საკრებულოს აპარატი' && item[1].name_geo != 'საკრებულოს სხდომის ოქმები' && item[1].name_geo != 'საჯარო ინფორმაცია') {
              return <div className="">
                <span>
                  {language == 1 && item[1].name_geo}
                  {language == 2 && item[1].name_eng}
                  {language == 3 && item[1].name_ru}


                </span>
                {Object.entries(menu).map((qveItem) => {
                  if (item[1].cat_id == qveItem[1].parent_id && qveItem[1].level == 2) {
                    if(item[1].name_geo == 'პრესცენტრი'){
                      return <span><a href={`/${qveItem[1].slug}`}>
                      <FaAngleRight />

                      {language == 1 && qveItem[1].name_geo}
                      {language == 2 && qveItem[1].name_eng}
                      {language == 3 && qveItem[1].name_ru}

                    </a></span>
                    }
                    return <span><Link to={`/${qveItem[1].slug}`}>
                      <FaAngleRight />

                      {language == 1 && qveItem[1].name_geo}
                      {language == 2 && qveItem[1].name_eng}
                      {language == 3 && qveItem[1].name_ru}

                    </Link></span>
                  }
                })}
              </div>
            }
          })}

        </div>
        <div className="credits padding-280px">
          <div className="">
            {/* <img src={logo} alt="" /> */}
            <img src={logo} alt="" />
          </div>
          <div className="">
            <span>
              &copy; {language == 1 && `ყველა უფლება დაცულია 2022`}
              {language == 2 && 'All rights reserved'}
              {language == 3 && 'все права защищены'}

            </span>
          </div>
          <div className="">
            <span>Created by <a href='https://proservice.ge/' target='blank'> ProService</a> </span>
          </div>

        </div>
        <div className="goToTop" onClick={goToTop}>
          <FaArrowAltCircleUp />
        </div>
      </div>


    </div>
  )
}

export default Footer