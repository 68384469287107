import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { useContext } from 'react';
import { SearchContext } from './Contexts/Context';
import { Helmet } from 'react-helmet-async';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
function MerisMoadgileebi() {
	const ref = useRef(null);
	const [width, setWidth] = useState(10);
	// import { useContext } from 'react'
	//import { SearchContext } from './Contexts/Context'
	// .filter(news => news[1].geo.title.includes(search)).
	const { search } = useContext(SearchContext);
	const { language } = useContext(SearchContext);
	const [members, setMembers] = useState({});

	useEffect(() => {
		const link = `https://khulo.gov.ge/api/members.php?`;
		fetch(link)
			.then(response => response.json())
			.then(data => {
				// console.log(data)
				setMembers(data);
				// console.log(data)
				// console.log('news', news)

				// console.log('object keys menu', Object.keys(menu))
				//console.log('object entries news', Object.entries(data))
				// console.log(Object.entries(data).length)
			});
		// console.log('news', news)
		// console.log('news 0', news[0])
		// console.log(JSON.parse(localStorage.getItem('languageLink')))

		//console.log('sheicvala!')
	}, []);

	const [menu, setMenu] = useState({});
	useEffect(() => {
		const link = 'https://khulo.gov.ge/api/site_menu1.php';
		fetch(link)
			.then(response => response.json())
			.then(data => {
				// console.log('data.menu',data.menu)
				setMenu(data.menu);

				//  console.log('menu', menu)

				//  console.log('object entries', Object.entries(menu))

				Object.entries(menu).map(item => {
					if (item[1].level == 1) {
						//console.log('entries item', item[1].name_eng , item[1].level)
					}
				});
			});
	}, []);
	const presscenterList = Object.entries(menu).length
		? Object.entries(menu).map((item, index) => {
				if (item[1].level == 1 && item[1].name_geo == 'მერია') {
					return Object.entries(menu).map((qveItem, index) => {
						if (qveItem[1].parent_id == item[1].cat_id) {
							const windoww = window.location.pathname;
							const result = windoww.split('/').pop();
							const slugg = qveItem[1].slug;
							return (
								<span key={index} className={result === slugg ? 'active' : ''}>
									<Link to={`/${qveItem[1].slug}`}>
										{language == 1 ? qveItem[1].name_geo : qveItem[1].name_eng}
									</Link>
								</span>
							);
						}
					});
				}
		  })
		: 'LOADING';
	const menuName = Object.entries(menu).length
		? Object.entries(menu).find((item, index) => {
				const windoww = window.location.pathname;
				const result = windoww.split('/').pop();
				const slugg = item[1].slug;
				if (result === slugg) {
					return language == 1 ? item[1].name_geo : item[1].name_eng;
				}
		  })
		: '';

	const membersList = Object.entries(members)
		.sort((a, b) => b[1].geo.date.localeCompare(a[1].geo.date))
		.filter(news => news[1].geo.title.includes(search))
		.map(news => {
			console.log(news[1].geo.m_title);
			if (
				news[1].geo.m_title?.includes('მერის მოადგილეები') ||
				news[1].geo.m_title?.includes('მერის მოადგილე')
			) {
				console.log(news[1]);
				return (
					<div>
						<div className='main' id={news[0]} key={news[0]}>
							<div className='card'>
								<div className='card-image'>
									<img src={'https://khulo.gov.ge/' + news[1].geo.img} alt='' />
								</div>
								<div className='card-content'>
									<p className='name'>
										{language == 1 ? news[1].geo.title : news[1].eng.title}
									</p>
									<p className='title'>
										{language == 1
											? news[1].geo.position
											: news[1].eng.position}
									</p>

									<div className='socials'>
										<ul>
											<li>
												<a
													href='https://www.facebook.com/KhuloCityHall'
													target='blank'
												>
													<FaFacebookF />
												</a>
											</li>
											<li>
												<a
													href='https://www.instagram.com/khulocityhall/'
													target='blank'
													className='bordered'
												>
													<FaInstagram />
												</a>
											</li>
											<li>
												{' '}
												<a
													href='https://www.youtube.com/channel/UCCP3nGfRLDohB8GWsDmM8mA'
													target='blank'
												>
													<FaYoutube />
												</a>
											</li>
										</ul>
									</div>

									<a
										href={news[1].geo.website}
										target='_blank'
										className='link'
									>
										{news[1].geo.website} www.economy.gov.ge
									</a>

									<p class='address'>{news[1].geo.address} ჭანტურიას ქ. №1</p>
									<p class='phone'>
										{news[1].geo.phone} ტელ: 299 11 11; 299 11 05
									</p>
									<Link to={`/meris-moadgileebi/${news[0]}`} className='more'>
										დაწვრილებით
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});

	useLayoutEffect(() => {
		setWidth(ref.current.offsetWidth);
	});

	return (
		<div>
			{/* <div class='card'>
				<div class='card-image'>
					<img
						src='https://khulo.gov.ge/uploads_script/members/2024/10/m2caxxiv6592a83.jpg'
						alt='Person'
					/>
				</div>
				<div class='card-content'>
					<p class='title'>
						პირველი ვიცე-პრემიერი, საქართველოს ეკონომიკის და მდგრადი
						განვითარების მინისტრი
					</p>
					<p class='name'>ლევან დავითაშვილი</p>
					<a href='https://www.economy.gov.ge' class='link'>
						www.khulo.gov.ge
					</a>
					<p class='address'>ჭანტურიას ქ. №1</p>
					<p class='phone'>ტელ: 299 11 11; 299 11 05</p>
					<a href='#' class='more'>
						დაწვრილებით
					</a>
				</div>
			</div> */}

			<motion.div
				intial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<div className='news-container margin-280px'>
					<header>
						<span className='big' ref={ref} style={{ marginRight: width / 2 }}>
							{' '}
							{language == 1 ? 'მერია' : 'KHULO CITY HALL'}
						</span>
						<span>
							{menuName
								? language == 1
									? menuName[1].name_geo
									: menuName[1].name_eng
								: ''}
						</span>
						<Helmet>
							<title>
								{menuName
									? language == 1
										? menuName[1].name_geo
										: menuName[1].name_eng
									: ''}
							</title>
						</Helmet>
					</header>
					<div>
						<div className='main'>
							<div className=''>{membersList}</div>
						</div>
						<div className='presscenter-info'>{presscenterList}</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
}

export default MerisMoadgileebi;
