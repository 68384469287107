const translations = {
    1: {
        something_wrong: 'დაფიქსირდა შეცდომა.',
        successful_subscription: 'თქვენ წარმატებით გამოიწერეთ სიახლეები.',
        email_min_5_symbols: 'ელ.ფოსტა უნდა შეიცავდეს 5-ზე მეტ სიმბოლოს.',
        invalid_email: 'არასწორი ელ.ფოსტის ფორმატი.',
        already_subscribed: 'თქვენ უკვე გამოწერილი გაქვთ სიახლეები.',
        valid_email: 'ვალიდური ელ.ფოსტის მისამართი.',
        get_news_via_email: 'მიიღე ყველა სიახლე ელ.ფოსტის საშუალებით',
        email: 'ელ.ფოსტა',
        subscribe: 'გამოწერა',
        search: 'ძიება',
        loading_records: 'ვეძებთ ჩანაწერებს, გთხოვთ დაელოდოთ...',
        no_records_found: 'ჩანაწერი ვერ მოიძებნა ჩვენს ბაზაში.',
        see_more: 'ვრცლად',
        search_result: "ძიების შედეგი:"
    },
    2: {
        something_wrong: 'Something went wrong.',
        successful_subscription: 'You have been successfully subscribed.',
        email_min_5_symbols: 'Email should contain more than 5 symbols.',
        invalid_email: 'Invalid email address format.',
        already_subscribed: 'You have already subscribed to this newsletter.',
        valid_email: 'Valid email address format.',
        get_news_via_email: 'Get all the news via Email',
        email: 'Email',
        subscribe: 'Subscribe',
        search: 'Search',
        loading_records: 'Loading records, please wait...',
        no_records_found: 'No records found for this query.',
        see_more: 'See more',
        search_result: "Search result for:"
    },
    3: {
        something_wrong: 'Что-то пошло не так.',
        successful_subscription: 'Вы успешно подписались.',
        email_min_5_symbols: 'Электронное письмо должно содержать более 5 символов.',
        invalid_email: 'Неверный формат адреса электронной почты.',
        already_subscribed: 'Вы уже подписались на эту рассылку.',
        valid_email: 'Действительный формат адреса электронной почты.',
        get_news_via_email: 'Получайте все новости по электронной почте',
        email: 'Эл. почта',
        subscribe: 'Подписаться',
        search: 'Поиск',
        loading_records: 'Загрузка записей, пожалуйста, подождите...',
        no_records_found: 'По данному запросу записей не найдено.',
        see_more: 'увидеть все',
        search_result: "Результат поиска для:"
    }
};

export const translate = (language, key) => translations[language][key] ?? key;