import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Helmet } from 'react-helmet-async';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SearchContext } from './Contexts/Context';
import { useContext } from 'react';

import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/ka';
function Anonsebi() {
	const location = useLocation();

	const { id } = useParams();

	// const [news, setNews] = useState(ArrayOfObject[id])
	const [moreNews, setMoreNews] = useState({});

	//  const [news,setNews] = useState({})
	const [exactNews, setExactNews] = useState({});
	const { language } = useContext(SearchContext);

	const [newsId, setNewsId] = useState({});
	// Lumos Fc
	useEffect(() => {
		fetch('https://khulo.lumos.com.ge/news/' + id)
			.then(response => response.json())
			.then(data => {
				setNewsId(data);
			});
	}, []);

	//More news lumos
	const [allNews, setAllNews] = useState({});

	useEffect(() => {
		fetch('https://khulo.lumos.com.ge/news/?category=საკრებულო')
			.then(response => response.json())
			.then(data => {
				setAllNews(data);
			});
	}, []);
	const AssemblyNews = () => {
		moment.locale('ka');
		return (
			<>
				{allNews?.results
					?.reverse()
					.filter(item => item.id !== id)
					.slice(0, 2)
					.map(item => (
						<Link to={`/sakrebulos-siakhleebi/${item.slug}`} key={item.id}>
							<div className='landing-items-container-infos' id={item.id}>
								<div className='new-image-wrapper'>
									<img src={item.img} alt='' />
								</div>
								<div>
									<span className='time'>
										{' '}
										{moment(item.created_at).format('LL')}{' '}
									</span>
									<span className='header'>{item.title}</span>
									<span className='vrclad'>
										<Link to={`/sakrebulos-siakhleebi/${item.slug}`}>
											{' ვრცლად'}
										</Link>
									</span>
								</div>
							</div>
						</Link>
					))}
			</>
		);
	};
	// useEffect(() => {
	// 	const link = 'https://khulo.gov.ge/api/news.php?lang=geo';
	// 	fetch(link)
	// 		.then(response => response.json())
	// 		.then(data => {
	// 			data['საკრებულოს სიახლეები'][id] &&
	// 				setExactNews(data['საკრებულოს სიახლეები'][id]);
	// 			data['საკრებულოს სიახლეები'] &&
	// 				setMoreNews(data['საკრებულოს სიახლეები']);
	// 			// console.log('data ID',data.სიახლეები[id].geo)

	// 			// console.log(data.სიახლეები[id].geo.text)

	// 			// console.log(data)
	// 			// console.log('news', news)

	// 			// console.log('object keys menu', Object.keys(menu))
	// 			// console.log('object entries news', Object.entries(data))
	// 		});
	// 	// console.log('news', news)
	// 	// console.log('news 0', news[0])
	// }, []);

	const newsList = Object.entries(moreNews).length
		? Object.entries(moreNews)
				.reverse()
				.filter(news => id !== news[0])
				.map((news, i) => {
					if (i <= 1) {
						return (
							<Link to={`/news/${news[0]}`}>
								<div
									className='landing-items-container-infos'
									id={news.id}
									key={news.id}
								>
									<div className='new-image-wrapper'>
										<img
											src={
												'https://khulo.gov.ge/' +
												(news[1].geo.thumb_img
													? news[1].geo.thumb_img
													: news[1].geo.img)
											}
											alt=''
										/>
									</div>
									<div>
										<span className='time'>
											{language == 1 ? news[1].geo.date : ''}
											{language == 2 ? news[1].eng.date : ''}
										</span>
										<span className='header'>
											{language == 1 ? news[1].geo.title : ''}
											{language == 2 ? news[1].eng.title : ''}
										</span>
										<span className='vrclad'>
											<Link to={`/news/${news[0]}`}>
												{language == 1 ? ' ვრცლად' : ''}
												{language == 2 ? ' See more' : ''}
											</Link>
										</span>
									</div>
								</div>
							</Link>
						);
					}
				})
		: 'loading news';

	return (
		<div className='news1-container margin-280px'>
			<header>
				<span className='big'>
					{' '}
					{'საკრებულო'}
					{/* {language == 2 ? ' KHULO CITY ASSEMBLY' : ''} */}
				</span>
				<span>
					{language == 1 ? ' საკრებულოს სიახლეები' : ''}
					{language == 2 ? ' ASSEMBLY NEWS' : ''}
				</span>
			</header>
			<div className='main'>
				<div className='sakrebulo-page'>
					<div className='main-picture'>
						<img src={newsId.img} alt='' />
						<span className='time'>{newsId.created_at}</span>
						<span className='header'>{newsId.title}</span>
					</div>
					<div className='main-content'>
						{
							<span
								className='main-content-text'
								dangerouslySetInnerHTML={{
									__html: newsId.description
										? newsId.description
												.replaceAll('<img', '<img tabIndex="-1"')
												.replaceAll(
													'../uploads',
													'https://www.khulo.gov.ge/uploads'
												)
										: '',
								}}
							></span>
						}

						<span>
							{' '}
							{language == 1 ? '   გააზიარე:' : ''}
							{language == 2 ? 'SHARE:' : ''}
							<a
								href={`https://www.facebook.com/sharer.php?u=https://khulo.gov.ge/uploads_script/share/${id}.html`}
								rel='noreferrer'
								target='_blank'
							>
								<FaFacebookF />
							</a>
							<a
								href={`https://twitter.com/intent/tweet?url=https://khulo.gov.ge/uploads_script/share/${id}.html&text=${newsId.title}`}
								rel='noreferrer'
								target='_blank'
							>
								<FaTwitter />
							</a>
						</span>
					</div>
				</div>
				<div className='moreNews'>
					<span>
						{' '}
						{language == 1 ? '   სხვა სიახლეები:' : ''}
						{language == 2 ? 'OTHER NEWS:' : ''}
					</span>
					<AssemblyNews />
				</div>
			</div>
		</div>
	);
}

export default Anonsebi;
