import { useState } from "react";
import { styles } from "../../constants/styles";
import { baseURL, formHeaders } from "../../constants/properties";
import { translate } from "../../helpers/translations";

export default function Subscribe({ language }) {
    const [subscribe, setSubscribe] = useState("");
    const [message, setMessage] = useState(null);

    // Subscribe to newsletter
    const handleSubscribe = async (e) => {
        // Check email validity
        if(!(await isValidEmail(subscribe)).valid) {
            setMessage({ type: 'error', message: (await isValidEmail(subscribe)).message });
            return;
        }

        // Make api call
        const formData = new URLSearchParams();
        formData.append('email', subscribe);

        const res = await fetch(`${baseURL}/subscribe.php`, {
            method: 'POST',
            headers: formHeaders,
            body: formData.toString()
        });
        
        // If not status ok return error
        if (!res.ok) {
            setMessage({ type: 'error', message: translate(language, 'something_wrong') });
        }

        // If everything is ok return success message
        setMessage({ type: 'success', message: translate(language, 'successful_subscription') });
    }

    // Check email validity
    const isValidEmail = async (email) => {
        // Check email length
        if (email.length <= 5) {
            return { valid: false, message: translate(language, 'email_min_5_symbols') };
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return { valid: false, message: translate(language, 'invalid_email') };
        }

        // Check email already subscribed or not
        const alreadySubscribed = await isAlreadySubscribed(email);
        if(alreadySubscribed) {
            return { valid: false, message: translate(language, 'already_subscribed') };
        }

        // Return valid email message
        return { valid: true, message: translate(language, 'valid_email') };
    };

    // Make api call to check if email is already subscribed
    const isAlreadySubscribed = async (email) => {
        const formData = new URLSearchParams();
        formData.append('email', email);

        const res = await fetch(`${baseURL}/check-email.php`, {
            method: 'POST',
            headers: formHeaders,
            body: formData.toString()
        });

        const status = await res.json();
        return Boolean(status);
    };

    return (
        <div className="footer-gmail margin-280px">
          <div>
            <span>
                {translate(language, 'get_news_via_email')}
            </span>
          </div>
          <div>
            <form>
              <input 
                type="email" 
                placeholder={translate(language, 'email')} 
                onChange={e => setSubscribe(e.target.value)} 
              />
              {message ? (
                <div style={styles.subscribeInput}>
                    <small style={styles.subscribeMessage}>
                        {message?.message}
                    </small>
                </div>
              ) : ''}
            </form>
          </div>
          <div>
            <button 
              type="submit" 
              style={styles.cursorPointer} 
              onClick={(e) => handleSubscribe(e)}
            >
              {translate(language, 'subscribe')}
            </button>
          </div>
        </div>
    );
}