const API_URL = 'https://khulo.lumos.com.ge/sakrebulo/';

export const getYears = async () => {
  const response = await fetch(`${API_URL}years/`);
  const data = await response.json();
  return data.results;
};

export const getAngarishebi = async (year) => {
  const response = await fetch(`${API_URL}angarishebi/?year=${year}`);
  const data = await response.json();
  return data.results;
};


// export const getAngarishebiBySlug = async (slug) => {
//   const response = await fetch(`${API_URL}${slug}`);
//   const data = await response.json();
//   return data.results;
// };


